import React, { Component } from "react";
import { withStyles, MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import { Link } from "react-router-dom";
import { PF_LESSONS, WATCH, MAIN_SCREEN } from "../../../constants/routes";
import MainHeader from "../MainHeader";
import { auth, db } from "../../../firebase";
import VOLTAR from "../Images/Voltar.png";
import _ from 'lodash';
import Checkbox from '@material-ui/core/Checkbox';
import blue from '@material-ui/core/colors/blue';
import swal from 'sweetalert';
import $ from "jquery";
import Loading from "../Loading";
import CircularProgress from '@material-ui/core/CircularProgress';

class PFLessons extends Component {
    constructor(props) {
        super(props);
        this.state = {
            currentUser: auth.getAuthUser(),
            questions: {},
            answers: {},
            checkBox: [],
            classes: {},
            isLoading: true,
            mailLoading: false
        }
    }

    componentDidMount() {
        this.getInformation();
    }

    getInformation() {
        let userUid = auth.getAuthUser().uid;
        db.refNode(`Users/${userUid}`).once("value").then((_user) => {
            const user = _user.val()
            if (user) {
                const {accountType, email} = user;
                var type;
                if(accountType == "Futuro Empreendedor" || accountType == "Micro Empreendedor Individual" || accountType == "Produtor Rural")
                type = "PF"
                else
                type = "PJ"

                this.setState({
                    userType: type,
                    userEmail: email
                });
                this.setTypeQuestions(accountType);
                this.getClasses();
            }
        }).catch((error) => {
            // console.log("Error", error);
        });
    }

    exportPDF(email, pdf) {
        this.setState({ mailLoading: true }, () => {
            $.ajax({
                type: "GET",
                url:
                    "https://us-central1-dbopen-capacita.cloudfunctions.net/functionMail",
                data: {
                    email: email,
                    pdf: pdf
                },
                dataType: "JSON",
                success: () => {
                    swal({
                        text: "Email enviado com sucesso!",
                        icon: "success",
                        buttons: {
                            cancel: false,
                            confirm: true,
                        },
                        dangerMode: true,
                    });
                },
                error: () => {
                    swal({
                        text: "Erro ao enviar email!",
                        icon: "error",
                        buttons: {
                            cancel: false,
                            confirm: true,
                        },
                        dangerMode: true,
                    });
                },
                complete: () => {
                    this.setState({ mailLoading: false });
                }
            });
        });
    }

    setTypeQuestions(type) {
        if (type == "Futuro Empreendedor" || type == "Micro Empreendedor Individual" || type == "Produtor Rural") {
            db.refNode(`Questions/PF`).once("value").then((questions) => {
                if (questions.val()) {
                    this.setState({ questions: questions.val() }, () => {
                        this.setAnswers();
                    });
                }
            }).catch(() => {
                swal({
                    text: "Erro ao carregar perguntas",
                    icon: "error",
                    buttons: {
                        cancel: false,
                        confirm: true,
                    },
                    dangerMode: true,
                });
            });
        } else {
            db.refNode(`Questions/PJ`).once("value").then((questions) => {
                if (questions.val()) {
                    this.setState({ questions: questions.val() }, () => {
                        this.setAnswers();
                    });
                }
            }).catch(() => {
                swal({
                    text: "Erro ao carregar perguntas",
                    icon: "error",
                    buttons: {
                        cancel: false,
                        confirm: true,
                    },
                    dangerMode: true,
                });
            });
        }
    }

    setAnswers = () => {
        let type = this.state.userType;
        let uid = auth.getAuthUser().uid;
        db.refNode(`Forms/${type}`).orderByChild("uid").equalTo(uid).once("value").then(answersData => {
            if (answersData.val()) {
                let array = _.values(answersData.val());
                let answers = array[0];
                if(answers["uid"]){
                    delete answers["uid"];
                    this.setState({answers});
                }
            }
        });
    }

    renderAnswer(answer){
        if(answer == "sim"){
            return "Sim";
        }else if(answer == "maisOuMenos"){
            return "Mais ou Menos";
        }else{
            return "Não";
        }
    }

    handleChange = name => event => {
        this.setState({ [name]: event.target.checked });
    };

    getClasses() {
        db.refNode(`Classes/`).orderByChild("classType").equalTo("question").once("value").then(classes => {
            if (classes.val()) {
                this.filterClasses(classes.val());
            }
        }).catch(() => {
            swal({
                text: "Erro ao carregar aulas",
                icon: "error",
                buttons: {
                    cancel: false,
                    confirm: true,
                },
                dangerMode: true,
            });
        });
    }

    filterClasses(classData){
        let array = _.values(classData);
        let type = this.state.userType;
        _.each(this.state.questions, (filter, key) =>{
            let data = _.filter(array, {questionId: `${key}`, themeType: type});
            if(_.size(data) > 0){
                classData = _.values(data);
                this.setState(prevState => ({
                    classes: {
                        ...prevState.classes,
                        [key]: classData[0]
                    }
                }), () => {
                    this.isChecked();
                    this.setState({isLoading: false});
                });
            }else{
                this.setState({isLoading: false});
            }
        });
    }

    isChecked = () => {
        let userUid = auth.getAuthUser().uid;
        const checked = [];
        db.refNode(`Users/${userUid}/ratedClasses`).once("value").then((ratedClasses) => {
            const _ratedClasses = ratedClasses.val();
            _.each(this.state.classes, (classData) => {
                const uid = classData.uid;
                if (_ratedClasses && _ratedClasses[uid] != undefined) {
                    checked.push({uid : uid, checked : _ratedClasses[uid]})
                } else {
                    checked.push({uid : uid, checked : false})
                }
            })
            // console.log(checked);
            this.setState({
                checkBox: checked
            });
        });
    }

    renderDone(classes, classe) {
        if (classe) {
            let check = this.state.checkBox.find(ratedClass => ratedClass.uid == classe.uid);
            if(!check) {
                check = false;
            }
            return <Checkbox
                id = "fadf"
                className={classes.size}
                color="primary"
                checked={!!check.checked}
            />;
        } else {
            return <Checkbox
                id = "fadf"
                className={classes.size}
                color="primary"
                checked={false}
            />;
        }
    }

    render() {
        const { classes } = this.props;

        return (
            this.state.isLoading ?
                <Loading/>
            :
            <div className="container-fluid">
                <div className="background">
                    <MainHeader  logado={this.state.currentUser ? true : false} />
                    <div className="col-xs-12 col-md-10 col-md-offset-1">
                        <div className="col-md-8">
                            <Link to={MAIN_SCREEN}>
                                <div className="col-lg-1 col-md-2 col-xs-3">
                                    <img
                                        className="img-responsive center-block image-back"
                                        src={VOLTAR}
                                    />
                                </div>
                                <u className={classes.link}>Voltar</u>
                            </Link>
                        </div>
                    </div>
                    <div className="col-md-8 col-md-offset-0 col-xs-12" style={{ textAlign: 'center', marginTop: "2%" }}>
                        <span className="responsive-title">VÍDEO AULAS QUESTIONÁRIO</span><br />
                    </div>
                    <div className="col-md-2 col-md-offset-1 lg-div" style={{ textAlign: 'right', marginTop: "2%" }}>
                        <span className="concluida">Aula concluída</span>
                    </div>
                    <div id="quiz-form">
                        <div className="col-xs-12 col-md-10 col-md-offset-1" style={{ marginTop: "3%" }}>
                            {
                                _.map(this.state.questions, (question, key) => {
                                    const lesson = this.state.classes[key] ? this.state.classes[key] : null;
                                    let number = key + 1;
                                    let modalId = lesson ? `#${lesson.uid}` : "#";
                                    return (
                                        <div>
                                            <div className="row">
                                                <div className="form-row">
                                                    <div className="form-group col-md-5">
                                                        <spam className="quiz-label">{question}</spam><br />
                                                        <spam className="quiz-resposta">(Sua resposta: <span className="textRed">{this.renderAnswer(this.state.answers[`answer${number}`])}</span>)</spam>
                                                    </div>
                                                    <div className="col-md-3 col-xs-12">
                                                        <div className="col-md-9 col-xs-8">
                                                            { lesson ?
                                                            <Link to={{ pathname: WATCH, state: { lesson: lesson, related: "notShow" } }}>
                                                                <div className="col-md-12 col-xs-12 assistir-aula-min clickable mobile-div">
                                                                    <i className="fas fa-play icon-middle"></i>&nbsp;&nbsp;<span>Assistir Aula</span>
                                                                </div>
                                                            </Link>
                                                            : ""
                                                            }
                                                        </div>
                                                    </div>
                                                    <div className="col-md-3 col-xs-12">
                                                        <div className="col-md-9 col-xs-8">
                                                            {lesson ?
                                                                <div data-toggle="modal" data-target={modalId} className="col-md-12 col-xs-12 guia-de-referencia-min clickable mobile-div">
                                                                    <i className="far fa-folder icon-middle"></i>&nbsp;&nbsp;<span>Referência</span>
                                                                </div>
                                                            : ""}
                                                        </div>
                                                    </div>
                                                    <div className="col-md-1 col-xs-12">
                                                        <div className="col-md-9 col-xs-8">
                                                            {/* <div className="col-md-2 col-xs-3 mobile-div"> */}
                                                                <MuiThemeProvider theme={theme}>
                                                                    {"Concluída:" && this.renderDone(this.state.classes, this.state.classes[key])}
                                                                </MuiThemeProvider>
                                                            {/* </div> */}
                                                        </div>
                                                    </div>
                                                </div>
                                                {lesson ?
                                                    this.renderModal(lesson.guideURL, lesson.uid, classes)
                                                : ""}
                                            </div>
                                            <div>
                                                <hr style={{ borderColor: '#D6D8D8', borderWidth: '1px' }} />
                                            </div>
                                        </div>
                                    );
                                })
                            }
                        </div>
                    </div>
                    <div className="col-xs-12 col-md-10 col-md-offset-1" style={{ marginBottom: '2%', marginTop: '1%' }}>
                        <div className="col-md-8">
                            <Link to={PF_LESSONS}>
                                <div className="col-lg-1 col-md-2 col-xs-3">
                                    <img
                                        className="img-responsive center-block image-back"
                                        src={VOLTAR}
                                    />
                                </div>
                                <u className={classes.link}>Voltar</u>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>

        );
    }

    renderModal(URL, id, classes) {
        return <div id={id} className="modal fade" tabindex="-1" role="dialog">
            <div id="print-area" className="modal-dialog big-modal" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <button id="modalClose" type="button" className="close not-print" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                        <p id="print-title" className="title-modal-min modal-title">Guia de Referência</p>
                    </div>
                    <div className="modal-body">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-md-12 modal-color-icon row">
                                    <div className="col-md-12 col-xs-12 mobile-div">
                                        {!this.state.mailLoading ?
                                            <div className="clickable" onClick={() => { this.exportPDF(this.state.userEmail, URL) }}>
                                                <i className="far fa-envelope modal-icon"></i>&nbsp;&nbsp;<span className="modal-text-icon">Enviar por email ({this.state.userEmail})</span>
                                            </div>
                                            :
                                            <div className="text-center">
                                                <CircularProgress size={40} className={classes.progress} />
                                            </div>
                                        }
                                    </div>
                                </div>
                                <div className="col-md-12 guia-text">
                                    <object className="pdf-iframe" data={URL} type="application/pdf">
                                        <iframe src={URL}></iframe>
                                    </object>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    }

}
const styles = {
    root: {
        flex: 1
    },
    body: {
        fonWeight: 100
    },
    text: {
        fontFamily: "Gotham Rounded Medium",
        fontSize: "25pt",
        color: '#505050'
    },
    link: {
        fontFamily: "Gotham Rounded Book",
        fontSize: "20pt",
        color: 'black'
    },
    progress: {
        color: '#17549B'
    }
};

const theme = createMuiTheme({
    palette: {
        primary: blue,
    }
});

// const authCondition = (authUser) => !!authUser;
// PFLessons = withAuthorization(authCondition)(PFLessons);
PFLessons = withStyles(styles)(PFLessons);
export default PFLessons;
