import React from 'react';
import ReactDOM from 'react-dom';

import 'tether';
import 'bootstrap';
import 'bootstrap/dist/css/bootstrap.css';
import 'font-awesome/css/font-awesome.min.css';
import './index.css';

import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import App from './App';
// import registerServiceWorker from './registerServiceWorker';

Sentry.init({
  dsn:
    'https://552b666ada6c41f289db7f70ac2e8ced@o1326056.ingest.sentry.io/6586361',
  integrations: [new BrowserTracing()],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1,
});

ReactDOM.render(<App />, document.getElementById('root'));

// registerServiceWorker();
