import * as firebase from 'firebase';

const normalConfig = {
  apiKey: 'AIzaSyBoev4pnKtwfl2quo3hBfMhVv4QPxGTQgo',
  authDomain: 'dbopen-capacita.firebaseapp.com',
  databaseURL: 'https://dbopen-capacita.firebaseio.com',
  projectId: 'dbopen-capacita',
  storageBucket: 'dbopen-capacita.appspot.com',
  messagingSenderId: '136360854056',
};

if (!firebase.apps.length) {
  firebase.initializeApp(normalConfig);
}

const db = firebase.database();
const auth = firebase.auth();

export { db, auth };
