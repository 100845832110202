import React, { Component } from "react";
import logohorizontal from "./Assets/logo-horizontal.png"
import f1image from "./Assets/F1.png"
import assinatura from "./Assets/Assinatura.png"
import qrCode from "./Assets/qrcode-teste.jpg"
import { db } from "../../../firebase";
import "./Assets/styles.css"
import { array, func } from "prop-types";
import _ from 'lodash';
import Loading from "../Loading";
import swal from "sweetalert";
import * as routes from "../../../constants/routes";
import { withStyles } from '@material-ui/core/styles';

class CertificateAutentication extends Component {
    constructor(props) {
        super(props);

        this.state = {
            name: '',
            hour: '',
            course: '',
            date: '',
            url: '',
            isLoading: true,
            empty: true
        }
    }

    componentDidMount() {
        console.log('props: ', this.props)
        console.log('computedMatch: ', this.props.computedMatch)
        const idDoCertificado = this.props.computedMatch.params.idDoCertificado

        db.refNode('Users/').orderByChild('certificates').once('value').then(snapshot => {
            const users = snapshot.val();
            const user = _.filter(users, _user => _user.certificates != undefined && _user.certificates[idDoCertificado] != undefined);
            console.log('user', user)
            return user != [] ? user[0] : undefined
        }).then(user => {
          console.log('user', user)
          if (user != undefined)
            {
              this.setState(
                  {
                      name: user.name,
                      hour: user.certificates[idDoCertificado].hour,
                      course: user.certificates[idDoCertificado].course,
                      date: user.certificates[idDoCertificado].date,
                      url: 'https://beta.capacitampe.com.br/autenticacao/' + idDoCertificado,
                      isLoading: false,
                      empty: false
                  }
              )
          } else {
            swal({
                text: "Certificado não encontrado! Certifique-se de que o código está correto.",
                icon: "error",
                buttons: {
                    confirm: true,
                },
                dangerMode: true,
            }).then(() => {
                this.props.history.push(routes.START)
            })/*quando clicar no ok cai no then*/
          }
        })



    }
    render() {

        return (
            this.state.isLoading ?
                <Loading />
                :
                < div id="div1" >
                    <div id="div2">
                        <img id="idImg2" src={f1image} />
                        <h1 id="Text">
                            Certificado de Conclusão
			            </h1>
                        <div id="div3">
                            Certificamos que {this.state.name.toUpperCase()} concluiu com sucesso {this.state.hour} do curso {this.state.course.toUpperCase()} em {this.state.date}.
			            </div>
                        <img id="idImg" src={logohorizontal} />
                        <div id="div4">
                            <hr style={{ width: '200px', size: '100' }} />
                            <p id='Text2'>Maurício Octávio Nascimento de Oliveira</p>
                            <p id='Text2'>Fundador e Diretor Geral</p>
                        </div>
                        <img id="Assinatura" src={assinatura} />
                        <img id="QRcode" src={qrCode} />
                    </div>
                    <p style={{ width: '500px' }} id="Text3">Autenticação: {this.state.url}</p>
                </div >
        );
    }
}

const styles = {
	html: {
		marginTop: '0px',
		marginBottom: '0px',
		marginLeft: '0px',
		marginRight: '0px',
		padding: 0
	},
	body: {
		marginTop: '0px',
		marginBottom: '0px',
		marginLeft: '0px',
		marginRight: '0px',
		padding: 0,
		maxHeight: '595px'
	}
};

CertificateAutentication = withStyles(styles)(CertificateAutentication);
export default CertificateAutentication;
