import React, { Component } from "react";
import { withStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import logoImg from "../Images/logo.png";
import logoSDEImg from "../Images/Sde_Azul_Prancheta.png";
import BrasilStartupsImg from "../Images/BrasilStartups.png";

class Loading extends Component {

    constructor(props) {
        super(props);
    }

    render() {
        const { classes } = this.props;
        return (
            <div className="container-fluid">
                <div className="background">
                    <div className="row text-center" style={{ padding: '20px 0'}}>
                        <div className="col-md-4 col-xs-4">
                            <img
                                src={logoImg}
                                alt="Capacita Admin"
                                width="365vw"
                            />
                        </div>
                        <div className="col-md-4 col-xs-4">
                            <img
                                src={logoSDEImg}
                                alt="Capacita SDE"
                                width="365vw"
                            />
                        </div>
                        <div className="col-md-4 col-xs-4">
                            <img
                                src={BrasilStartupsImg}
                                alt="Brasil Startups"
                                width={"200vw"}
                            />
                        </div>
                    </div>
                    <div className="row text-center">
                        <div className="col-md-12 col-xs-12">
                            <span className="text-loading">Carregando...</span>
                        </div>
                    </div>
                    <div className="col-md-12 col-xs-12 text-center mobile-div">
                        <CircularProgress size={180} className={classes.progress} />
                    </div>
                </div>
            </div>
        );
    }
}

const styles = {
    root: {
        flex: 1
    },
    body: {
        fonWeight: 100
    },
    progress: {
        color: '#17549B',
        marginTop: '5%'
    }
};

export default withStyles(styles)(Loading);
