import React, { Component } from "react";
import logoImg from "../Images/logo.png";
import logoSDEImg from "../Images/Sde_Azul_Prancheta.png";
import BrasilStartupsImg from "../Images/BrasilStartups.png";
import sairImg from "../Images/Sair.png";
import swal from "sweetalert";
import { auth, db } from "../../../firebase";
import { MAIN_SCREEN } from "../../../constants/routes";
import { Link } from "react-router-dom";
import * as mask from "../Common/mascaras";
import $ from "jquery";
import _ from "lodash";
import moment from "moment";
import * as routes from "../../../constants/routes";
import entrarImg from "../Images/Capturar.PNG";
import Cookies from "universal-cookie";

class MainHeader extends Component {
	constructor(props) {
		super(props);
		this.state = {
			logado: props.logado,
			cart: "",
			cookies: new Cookies()
		};
		// this.handlerGetCart = this.handlerGetCart.bind(this);
	}
	componentDidMount() {
		// const cart = localStorage.getItem("cart");
		// this.setState({ cart });
	}
	// async componentWillReceiveProps(props) {
	// 	//// console.log(props);
	// 	var cart = await localStorage.getItem("cart");
	// //	// console.log("passou aqui", cart);
	// 	if (cart != null) {
	// 		if (props.isCart) this.setState({ cart: props.cart });
	// 		else this.setState({ cart: cart });
	// 	} else {
	// 		this.setState({ cart: props.cart });
	// 	}
	// }
	// handlerGetCart() {
	// //	// console.log("entrou");
	// 	const cart = localStorage.getItem("cart");
	// 	this.setState({ cart });
	// }
	doLogoff() {
		swal({
			text: "Deseja sair do sistema?",
			icon: "warning",
			buttons: true,
			dangerMode: true,
		}).then(Exit => {
			if (Exit) {
				auth
					.doSignOut()
					.then(() => {
					//	// console.log("Saindo");
					})
					.catch(error => {
						// console.log("Error:", error);
						swal("Erro ao sair do sistema!");
					});
			}
		});
	}
	login(loginUser) {
		const { password } = this.state;
		const { history } = this.props;
		let userEmail = _.values(loginUser);
		this.setState({ error: null });
		auth
			.doSignInWithEmailAndPassword(userEmail[0].email, password)
			.then(snapshot => {
				db.onceGetUsers(snapshot.uid).then(user => {
					let authUser = user.val();
					if (user.val() != null) {
						db.refNode(`Users/${authUser.uid}`).update({
							lastLogin: moment().valueOf(),
						});
						this.state.cookies.set("userId", authUser.uid);
						this.state.cookies.set("userType", authUser.type);
						swal({
							text: "Logado com sucesso",
							icon: "success",
						});
						// history.push(routes.MAIN_SCREEN);
					} else {
						alert("Acesso exclusivo para usuários");
					}
				});
			})
			.catch(() => {
				swal({
					text: "Usuário ou senha incorretos!",
					icon: "error",
					buttons: {
						cancel: false,
						confirm: true,
					},
					dangerMode: true,
				});
			});
	}
	Submit = event => {
		this.setState({ showModal: false });
		$("#modalClose").click();
		if (this.state.loginType == "PF") {
			const cpf = this.state.cpf;
			const pass = this.state.password;
			const isInvalid = pass === "" || cpf == "" || !cpf;
			if (!isInvalid) {
				db.refNode(`Users/`)
					.orderByChild("cpf")
					.equalTo(cpf)
					.once("value")
					.then(loginUser => {
						if (loginUser.val()) {
							this.login(loginUser.val());
						} else {
							swal({
								text: "CPF ou senha incorretos!",
								icon: "error",
								buttons: {
									cancel: false,
									confirm: true,
								},
								dangerMode: true,
							});
						}
					})
					.catch(() => {
						swal({
							text: "Ocorreu algum erro!",
							icon: "error",
							buttons: {
								cancel: false,
								confirm: true,
							},
							dangerMode: true,
						});
					});
			} else {
				swal({
					text: "Por favor preencha todos os campos!",
					icon: "error",
					buttons: {
						cancel: false,
						confirm: true,
					},
					dangerMode: true,
				});
			}
		} else {
			const cnpj = this.state.cnpj;
			const pass = this.state.password;
			const isInvalid = pass == "" || cnpj == "" || !cnpj;
			if (!isInvalid) {
				db.refNode(`Users/`)
					.orderByChild("cnpj")
					.equalTo(cnpj)
					.once("value")
					.then(loginUser => {
						if (loginUser.val()) {
							this.login(loginUser.val());
						} else {
							swal({
								text: "CNPJ ou senha incorretos!",
								icon: "error",
								buttons: {
									cancel: false,
									confirm: true,
								},
								dangerMode: true,
							});
						}
					})
					.catch(() => {
						swal({
							text: "Desculpe, ocorreu algum erro!",
							icon: "error",
							buttons: {
								cancel: false,
								confirm: true,
							},
							dangerMode: true,
						});
					});
			} else {
				swal({
					text: "Por favor preencha todos os campos!",
					icon: "error",
					buttons: {
						cancel: false,
						confirm: true,
					},
					dangerMode: true,
				});
			}
		}
		event.preventDefault();
	};

	render() {
		return (
			<div className="row">
				<nav className="navbar navbar-default main-header">
					<div className="col-md-12" style={{ padding: '10px' }}>
						<div className="col-md-3 col-xs-2">
							<Link to={MAIN_SCREEN}>
								<img
									className="img-responsive"
									src={logoImg}
									alt="Capacita Admin"
									width="250vw"
									style={{ marginTop: "1%", float: "left" }}
								/>
							</Link>
						</div>
            <div className="col-md-3 col-xs-3">
							<Link to={MAIN_SCREEN}>
								<img
									className="img-responsive"
									src={logoSDEImg}
									alt="Capacita SDE"
									width="250vw"
									style={{ marginTop: "1%", float: "left" }}
								/>
							</Link>
						</div>
            <div className="col-md-3 col-xs-2">
							<Link to={MAIN_SCREEN}>
								<img
									className="img-responsive"
									src={BrasilStartupsImg}
									alt="Brasil Startups"
									width="150vw"
									style={{ marginTop: "1%", float: "left" }}
								/>
							</Link>
						</div>
						{this.state.logado ? (
							<div className="col-md-3 col-xs-5" style={{ padding: 0 }}>
								<div style={{ paddingTop: 25 }}>
									<img
										className="clickable"
										src={sairImg}
										alt="Capacita Admin"
										width="100px"
										onClick={() => this.doLogoff()}
									/>
									{/* <Link
										to={{
											pathname: routes.CART,
										}}>
										<i
											className="fa fa-shopping-cart"
											style={{ fontSize: 17, marginLeft: 5 }}
											aria-hidden="true">
											{" "}
											{this.state.cart}
										</i>
									</Link> */}
								</div>
							</div>
						) : (
							<div className="col-md-3 col-xs-5" style={{ padding: 0 }}>
								<div style={{ paddingTop: 25 }}>
									<img
										data-toggle="modal"
										data-target="#loginModal"
										className="clickable"
										src={entrarImg}
										alt="Capacita Admin"
										width="100px"
										onClick={() => this.setState({ showModal: true })}
									/>
									{/* <Link
										to={{
											pathname: routes.CART,
										}}>
										<i
											className="fa fa-shopping-cart"
											style={{ fontSize: 17, marginLeft: 5 }}
											aria-hidden="true">
											{" "}
											{this.state.cart}
										</i>
									</Link> */}
								</div>
							</div>
						)}

						{this.state.showModal && (
							<div id="loginModal" className="modal fade" tabindex="-1" role="dialog">
								<div className="modal-dialog" role="document">
									<div className="modal-content">
										<div className="modal-header">
											<button
												id="modalClose"
												type="button"
												className="close"
												data-dismiss="modal"
												aria-label="Close"
												onClick={() => this.setState({ showModal: false })}>
												<span aria-hidden="true">&times;</span>
											</button>
										</div>
										<div className="modal-body">
											<div className="col-md-12 row">
												<div className="col-md-12">
													<label className="login-label">Tipo de conta</label>
												</div>
												<div className="form-group col-md-3">
													<div className="form-check">
														<label className="label-check-quiz">
															<input
																className="check-quiz"
																type="radio"
																name="loginType"
																defaultChecked
																checked={this.state.loginType == "PF"}
																onChange={() => this.setState({ loginType: "PF" })}
															/>
															<span className="label-text">PF</span>
														</label>
													</div>
												</div>
												<div className="form-group col-md-6">
													<div className="form-check">
														<label className="label-check-quiz">
															<input
																className="check-quiz"
																type="radio"
																name="loginType"
																checked={this.state.loginType == "PJ"}
																onChange={() => this.setState({ loginType: "PJ" })}
															/>
															<span className="label-text">PJ</span>
														</label>
													</div>
												</div>
											</div>
											<form id="form-login">
												<div className="form-group">
													<div className="col-md-12">
														<label for="login-user" className="login-label">
															{this.state.loginType == "PF" ? "CPF" : "CNPJ"}
														</label>
													</div>
													<div className="col-md-12">
														{this.state.loginType == "PF" ? (
															<input
																type="text"
																id="login-user"
																maxlength="14"
																className="form-control placeholder"
																placeholder=" Ex: 000.000.000-00"
																value={this.state.cpf ? this.state.cpf : ""}
																onChange={e => {
																	let input = e.target;
																	mask.maskCPF(input.value).then(masked => {
																		this.setState({
																			cpf: masked,
																		});
																	});
																}}
															/>
														) : (
															<input
																type="text"
																id="login-user"
																maxlength="18"
																className="form-control placeholder"
																placeholder=" Ex: 00.000.000/0000-00"
																value={this.state.cnpj ? this.state.cnpj : ""}
																onChange={e => {
																	let input = e.target;
																	mask.maskCNPJ(input.value).then(masked => {
																		this.setState({
																			cnpj: masked,
																		});
																	});
																}}
															/>
														)}
														<br />
													</div>
												</div>
												<div className="form-group">
													<div className="col-md-12">
														<label for="password-user" className="login-label">
															Senha
														</label>
													</div>
													<div className="col-md-12">
														<input
															type="password"
															id="password-user"
															className="form-control placeholder"
															placeholder=" Ex: 99***********"
															onChange={event =>
																this.setState({
																	password: event.target.value,
																})
															}
														/>
														<br />
													</div>
												</div>
												<div className="col-md-12">
													<span
														data-dismiss="modal"
														data-toggle="modal"
														data-target="#password-modal"
														id="password-recovery"
														className="clickable"
														onClick={() => this.setState({ showModal: false })}>
														Esqueceu a senha?
													</span>
												</div>
												<div className="row">
													<div className="col-xs-12 col-xs-offset-2">
														<button
															id="btn-entrar"
															type="submit"
															className="btn btn-default btn-lg clickable"
															onClick={this.Submit}>
															ENTRAR
														</button>
													</div>
												</div>
											</form>
										</div>
									</div>
								</div>
							</div>
						)}
					</div>
				</nav>
			</div>
		);
	}
}

export default MainHeader;
