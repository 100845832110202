import React from 'react';
import { useSelector } from 'react-redux';
import Input from 'src/components/Input';
import { AppState } from 'src/store';
import { normalizeCep } from 'src/utils/normalizations';
import { required, validCep } from 'src/utils/validations';

const Address: React.FC = () => {
  const state = useSelector<AppState, string | undefined>(
    state => state.form.signUp?.values?.state,
  );

  return (
    <div>
      <div className="row">
        <Input
          className="form-group col-md-5"
          label="CEP"
          name="cep"
          id="register-cep"
          placeholder=" Ex: 00000-000"
          validate={[required, validCep]}
          {...normalizeCep()}
        />
      </div>
      <div className="row">
        <Input
          className="form-group col-md-10"
          label="Logradouro"
          name="publicPlace"
          id="register-public-place"
          validate={[required]}
        />
      </div>
      <div className="row">
        <div className="form-row">
          <Input
            className="form-group col-md-4"
            label="Bairro"
            name="neighborhood"
            id="register-neighborhood"
            validate={[required]}
          />

          <Input
            className="form-group col-md-3"
            label="Nº"
            name="number"
            id="register-number"
            validate={[required]}
          />

          <Input
            className="form-group col-md-3"
            label="Complemento"
            name="complement"
            id="register-complement"
          />
        </div>
      </div>
      <div className="row">
        <Input
          className="form-group col-md-5"
          label="Cidade"
          name="city"
          id="register-city"
          validate={[required]}
        />

        <Input
          className="form-group col-md-5"
          label="Estado"
          name="state"
          id="register-state"
          validate={[required]}
        />
      </div>
      <div className="row">
        <Input
          className="form-group col-md-5"
          label="Região Nacional"
          name="nationalRegion"
          id="register-nationalRegion"
          validate={[required]}
        />
        {state === 'DF' && (
          <Input
            className="form-group col-md-5"
            label="Região Administrativa"
            name="administrativeRegion"
            id="register-administrativeRegion"
            validate={[required]}
          />
        )}
      </div>
    </div>
  );
};

export default Address;
