import React from 'react';
import Input from 'src/components/Input';
import {
  email,
  minLength,
  required,
  shouldBeEqual,
} from 'src/utils/validations';
import { Container } from './styles';

interface Props {}

const AuthData: React.FC<Props> = () => {
  return (
    <Container>
      <div
        className="col-md-12 col-xs-12"
        style={{ textAlign: 'center', marginTop: '3%' }}
      >
        <span className="title">Cadastro de conta e senha</span>
      </div>
      <div className="col-md-10 col-md-offset-3 col-xs-12">
        <div className="row">
          <Input
            className="form-group col-md-6"
            type="email"
            label="Email"
            name="email"
            id="register-email"
            placeholder=" Ex: email@email.com.br"
            autoComplete="email"
            validate={[required, email]}
          />
        </div>
        <div className="row">
          <Input
            className="form-group col-md-6"
            label="Senha"
            type="password"
            name="password"
            id="new-password"
            placeholder=" Ex: 9***********"
            autoComplete="new-password"
            validate={[required, minLength(6)]}
          />
        </div>
        <div className="row">
          <Input
            className="form-group col-md-6"
            label="Confirmar Senha"
            type="password"
            name="passwordConfirm"
            id="new-password-confirm"
            placeholder=" Ex: 9***********"
            autoComplete="new-password"
            validate={[required, shouldBeEqual('As senhas', 'password')]}
          />
        </div>
      </div>
    </Container>
  );
};

export default AuthData;
