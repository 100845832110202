import React from 'react'
import { withStyles } from '@material-ui/core/styles';
import { db } from "../../../firebase";
import Swal from "sweetalert2"
import  InputMask from 'react-input-mask';
import { email } from 'src/utils/validations';


class Modal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      submited: false,
      personData: {
        name: '',
        email: '',
        phone: ''
      },
      valid: false,
    };

    this.updateInput = this.updateInput.bind(this)

  }

 pushToDb(personData) {
      this.props.hideNewModal()
      Swal.fire(
        'Cadastrado com sucesso!',
        'Em breve entramos em contato.',
        'success'
      )
      db.addToWaitingList(personData)
  }

  updateInput(value, target) {
    const personData = { ...this.state.personData, [target]: value.target.value };
    if (
      !email(personData.email) &&
      personData.name.length && personData.email.length
    ) {
      this.setState({
        personData,
        valid: true
      });
    } else {
      this.setState({
        personData,
        valid: false
      });
    }
  }

  render() {
    return (
      <div className="newModal"  onClick={() => this.props.hideNewModal()}>
        <div className="modalContent" onClick={e => e.stopPropagation()}>
          <div className="modalHeader">
            Cadastro lista de espera
          </div>
          <div className="modalBody">
            <label htmlFor="nameInput" className="labelNewModal">*Nome</label>
            <input type="text" placeholder="Nome" id="nameInput" onChange={(e) => {
              this.updateInput(e, 'name'), this.state.submited = false
            }} />
            <br />
            <label htmlFor="emailInput" className="labelNewModal">*Email</label>
            <input type="email" placeholder="Email" id="emailInput" onChange={(e) => {
              this.updateInput(e, 'email'), this.state.submited = false
            }} />
            <br />
            <label htmlFor="phoneInput" className="labelNewModal">Telefone</label>
            <InputMask mask="(99)(99999-9999)" placeholder="Telefone (opcional)" id="phoneInput" onChange={(e) => {
              this.updateInput(e, 'phone'), this.state.submited = false
            }}  />
          </div>
          <div className="modalFooter">
            <button type="button" className="submitModalButton" style={this.state.valid ? {} : { backgroundColor: "#76787a" }} disabled={!this.state.valid} onClick={() => this.pushToDb(this.state.personData)}>Cadastrar</button>
            <button type="button" className="cancelModalButton" onClick={() => this.props.hideNewModal()}>Cancelar</button>
          </div>
        </div>
      </div>
    )
  }
}


const styles = {

  root: {
    flex: 1
  },
  body: {
    fonWeight: 100
  },
  text: {
    fontFamily: "Gotham Rounded Book",
    fontSize: "200%",
    color: '#505050'
  },
  text2: {
    fontFamily: "Gotham Rounded Book",
    fontSize: "230%",
    color: '#505050'
  },
  text3: {
    fontFamily: "Gotham Rounded Book",
    fontSize: "220%",
    color: '#505050'
  },
  link: {
    fontFamily: "Gotham Rounded Book",
    fontSize: "220%",
    color: '#4A90E2',
    cursor: 'pointer'
  },
  link2: {
    fontFamily: "Gotham Rounded Book",
    fontSize: "190%",
    color: '#4A90E2',
    cursor: 'pointer'
  },
};

export default withStyles(styles)(Modal);
