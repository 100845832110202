import React, { Component } from "react";
import { withStyles, MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import { auth, db } from "../../../firebase";
import _ from 'lodash';
import * as routes from "../../../constants/routes";
import withAuthorization from "../Session/withAuthorization";
import './quiz.css';
import Loading from "../Loading";
import logoImg from "../Images/logo.png";
import logoSDEImg from "../Images/Sde_Azul_Prancheta.png";
import BrasilStartupsImg from "../Images/BrasilStartups.png";

class Quiz extends Component {
    constructor(props) {
        super(props);
        this.state = {
            perguntas: [],
            formData: {
                uid: auth.getAuthUser().uid
            },
            title: "",
            isLoading: true
        }
    }

    componentDidMount() {
        this.getUserType();
    }

    submitForm(e) {
        e.preventDefault();
        let userData = this.state.formData;
        let userUid = auth.getAuthUser().uid;
        db.refNode(`Forms/${this.state.userType}/${userUid}`).update(userData).then(async () => {
            await db.refNode(`Users/${userUid}`).update({
              quiz: true,
            })
            this.props.history.push(routes.MAIN_SCREEN);
        }).catch((error) => {
            // console.log("Error", error);
        });
    };

    handleUserInput(e) {
        const { name, value } = e.target;
        this.setState(prevState => ({
            formData: {
                ...prevState.formData,
                [name]: value
            }
        }));
    }

    getUserType() {
        let userUid = auth.getAuthUser().uid;
        db.refNode(`Users/${userUid}`).once("value").then((userType) => {
            if (userType.val()) {
                const {type,accountType} = userType.val();
                this.setState({ userType: type });
                this.setTypeQuestions(accountType);
            }
        }).catch((error) => {
            // console.log("Error", error);
        });
    }

    setTypeQuestions(type) {
       // // console.log("TIPO: ",type)
        if (type == "Futuro Empreendedor" || type == "Micro Empreendedor Individual" || type == "Produtor Rural") {
         //  // console.log("entrou pessoa fisica")
            db.refNode(`Questions/PF`).once("value").then((questions) => {
                if (questions.val()) {
                    this.setState({ perguntas: questions.val() }, () =>
                        this.setState({ isLoading: false })
                    );
                    this.setState({ title: "Questionário de Pessoa Física" });
                }
            }).catch((error) => {
                // console.log("Error", error);
            });
        } else {
          //  // console.log("entrou pessoa juridica")
            db.refNode(`Questions/PJ`).once("value").then((questions) => {
                if (questions.val()) {
                    this.setState({ perguntas: questions.val() }, () =>
                        this.setState({ isLoading: false })
                    );
                    this.setState({ title: "Questionário de Pessoa Jurídica" });
                }
            }).catch((error) => {
                // console.log("Error", error);
            });
        }
    }

    getCriterio = (question) => {
        if(question >= 1 && question <= 5){
            return "(Critério CLIENTES)";
        }else if(question >= 6 && question <= 8){
            return "(Critério SOCIEDADE)";
        }else if(question >= 9 && question <= 14){
            return "(Critério LIDERANÇA)";
        }else if(question >= 15 && question <= 18){
            return "(Critério ESTRATÉGIAS E PLANOS)";
        }else if(question >= 19 && question <= 23){
            return "(Critério PESSOAS)";
        }else if(question >= 24 && question <= 27){
            return "(Critério PROCESSOS)";
        }else if(question >= 28 && question <= 30){
            return "(Critério INFORMAÇÕES E CONHECIMENTO)";
        }
    }

    render() {
        const { classes } = this.props;

        return (
            this.state.isLoading ?
                <Loading />
                :
                <div className="container-fluid">
                    <div className="background">
                        <div className="row">
                            <div className="col-md-4 col-xs-4 text-center">
                                <img
                                    src={logoImg}
                                    alt="Capacita Admin"
                                    width="365vh"
                                />
                            </div>
                            <div className="col-md-4 col-xs-4 text-center">
                                <img
                                    src={logoSDEImg}
                                    alt="Capacita SDE"
                                    width="365vh"
                                />
                            </div>
                            <div className="col-md-4 col-xs-4 text-center">
                                <img
                                    src={BrasilStartupsImg}
                                    alt="Brasil Startups"
                                    width="200vh"
                                />
                            </div>
                            <div className="col-md-12" style={{ textAlign: 'center', marginTop: "3%" }}>
                                <span className={classes.title}>{this.state.title}</span><br /><br /><br />
                            </div>
                            <div className="col-xs-10 col-xs-offset-1" style={{ textAlign: 'left', marginBottom: '5%' }}>
                                <span className={classes.subtitle}>Para cada um dos itens abaixo, escolha a opção que melhor represente seu de nível de domínio sobre o assunto.</span>
                            </div>
                            <div id="quiz-form">
                                <form id="quiz-form" onSubmit={(e) => this.submitForm(e)}>
                                    <div className="col-xs-10 col-xs-offset-1">
                                        {
                                            _.map(this.state.perguntas, (pergunta, key) => {
                                                let questionName = `answer${key + 1}`;
                                                return (
                                                    <div>
                                                        <div className="row">
                                                            <div className="form-row">
                                                                <div className="form-group col-md-6">
                                                                    <spam className="quiz-label">{pergunta} {this.state.userType == "PJ" ? this.getCriterio(key + 1) : ""}</spam>
                                                                </div>
                                                                <div className="col-md-6">
                                                                    <div className="form-group col-md-3">
                                                                        <div className="form-check">
                                                                            <label className="label-check-quiz clickable">
                                                                                <input required="required" className="clickable" type="radio" name={questionName} value="sim"
                                                                                    onChange={(e) => this.handleUserInput(e)} />&nbsp;
                                                                                <span className="label-text">Sim</span>
                                                                            </label>
                                                                        </div>
                                                                    </div>
                                                                    <div className="form-group col-md-6">
                                                                        <div className="form-check">
                                                                            <label className="label-check-quiz clickable">
                                                                                <input className="clickable" type="radio" name={questionName} value="maisOuMenos"
                                                                                    onChange={(e) => this.handleUserInput(e)} />&nbsp;
                                                                                <span className="label-text">Mais ou menos</span>
                                                                            </label>
                                                                        </div>
                                                                    </div>
                                                                    <div className="form-group col-md-3">
                                                                        <div className="form-check">
                                                                            <label className="label-check-quiz clickable">
                                                                                <input className="clickable" type="radio" name={questionName} value="nao"
                                                                                    onChange={(e) => this.handleUserInput(e)} />&nbsp;
                                                                                <span className="label-text">Não</span>
                                                                            </label>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div>
                                                            <hr style={{ borderColor: '#D6D8D8', borderWidth: '1px' }} />
                                                        </div>
                                                    </div>
                                                );
                                            })
                                        }
                                        <div className="row" style={{ marginBottom: "5%", marginTop: "5%" }}>
                                            <div className="col-md-8">
                                                <button type="submit" id="btn-salvar-dados" className="btn btn-default btn-lg col-lg-5 col-md-4 col-xs-12 clickable">SALVAR DADOS</button>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
        );
    }
}
const styles = {
    root: {
        flex: 1
    },
    body: {
        fonWeight: 100
    },
    title: {
        fontFamily: "Gotham Rounded Book",
        fontSize: "40pt",
        color: '#F5A623'
    },
    subtitle: {
        fontFamily: "Gotham Rounded Book",
        fontSize: "15pt",
        color: '#616160'
    },
    progress: {
        color: '#17549B'
    }
};

// const authCondition = (authUser) => !!authUser;
// Quiz = withAuthorization(authCondition)(Quiz);
Quiz = withStyles(styles)(Quiz);
export default Quiz;
