import React, { useCallback } from 'react';
import Swal from 'sweetalert2';
import logoImg from '../../../assets/images/logo.png';
import logoSDEImg from '../../../assets/images/Sde_Azul_Prancheta.png';
import BrasilStartupsImg from '../../../assets/images/BrasilStartups.png';
import EPP2 from '../../../assets/images/EPP2.png';
import FEP from '../../../assets/images/FuturoEmpreendedor.png';
import MEI from '../../../assets/images/MEI.png';
import MPE from '../../../assets/images/MPE.png';
import PR from '../../../assets/images/PR.png';

interface Props {
  selectType(type: string): void;
}

const SelectType: React.FC<Props> = ({ selectType }) => {
  const selectPRType = useCallback(async () => {
    const { value: type } = await Swal.fire({
      title: 'Selecione como deseja se cadastrar',
      input: 'radio',
      inputOptions: { 'PR.PF': 'Pessoa física', 'PR.PJ': 'Pessoa jurídica' },
      inputValidator: value => {
        if (!value) {
          return 'Por favor, escolha uma opção';
        }
        return null;
      },
    });
    selectType(type);
  }, [selectType]);

  return (
    <div className="container-fluid">
      <div className="background">
        <div className="row" style={{ padding: '20px 0' }}>
          <div className="col-md-4">
            <img
              className="img-responsive center-block"
              src={logoImg}
              alt="Capacita Admin"
              width="365vh"
            />
          </div>
          <div className="col-md-4">
            <img
              className="img-responsive center-block"
              src={logoSDEImg}
              alt="Capacita SDE"
              width="365vh"
            />
          </div>
          <div className="col-md-4">
            <img
              className="img-responsive center-block"
              src={BrasilStartupsImg}
              alt="Brasil Startups"
              width="200vh"
            />
          </div>
          <div
            style={{ marginTop: -10, height: 26 }}
            className="col-md-10 col-md-offset-1"
          >
            <hr style={{ borderColor: '#D6D8D8', borderWidth: '2px' }} />
          </div>
          <div
            className="col-md-12"
            style={{ textAlign: 'center', marginTop: 0 }}
          >
            <span className="resposive-title-dashboard">Boas vindas!</span>
            <br />
          </div>

          <div
            className="col-md-12"
            style={{ textAlign: 'center', marginTop: 0 }}
          >
            <span>
              Para continuar, selecione a opção que melhor se adqua ao seu
              perfil de empreendedor:
            </span>
          </div>
        </div>
      </div>

      <div className="col-md-12 col-md-offset-1" style={{ marginTop: '1%' }}>
        <div className="col-xs-6 col-md-2 ">
          <div onClick={() => selectType('PF')}>
            <img
              id="PF"
              className="img-responsive center-block image"
              src={FEP}
              alt="Futuro empreendedor"
            />
          </div>
        </div>
        <div className="col-xs-6 col-md-2 ">
          <div onClick={() => selectType('MEI')}>
            <img
              id="MEI"
              className="img-responsive center-block image"
              src={MEI}
              alt="Micro empreendedor Individual"
            />
          </div>
        </div>
        <div className="col-xs-6 col-md-2 ">
          <div onClick={() => selectType('MPE')}>
            <img
              id="MPE"
              className="img-responsive center-block image"
              src={MPE}
              alt="Micro e pequena empresa"
            />
          </div>
        </div>
        <div className="col-xs-6 col-md-2 ">
          <div onClick={() => selectType('EPP')}>
            <img
              id="EPP"
              className="img-responsive center-block image"
              src={EPP2}
              alt="Empresa de pequeno porte"
            />
          </div>
        </div>
        <div className="col-xs-6 col-md-2">
          <div onClick={selectPRType}>
            <img
              id="PR"
              className="img-responsive center-block image"
              src={PR}
              alt="Produtor Rural"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default SelectType;
