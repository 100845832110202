const getTypeName = (type: string) => {
  if (type.includes('PR')) {
    return 'Produtor Rural';
  }

  if (type.includes('MEI')) {
    return 'Micro Empreendedor Individual';
  }

  if (type.includes('MPE')) {
    return 'Micro e Pequena Empresa';
  }

  if (type.includes('EPP')) {
    return 'Empresa de Pequeno Porte';
  }

  return 'Futuro Empreendedor';
};

export default getTypeName;
